<template>
  <v-container class="align-content-center">
    <v-layout row wrap justify-center>
      <v-flex xs11 md6 my-4>
        <v-card v-if="user.FirstName">
          <v-container>
            <!--Avatar-->
            <v-row>
              <v-col class="d-inline-flex justify-center">
                <Avatar :user="attendance"></Avatar>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="justify-center">
                <div class="display-1 text-center">
                  {{ user.FirstName }} {{ user.Surname }}
                </div>
                <div class="title text-center">
                  ({{ score }}
                  <v-icon color="blue">mdi-cards-diamond</v-icon>points)
                </div>
                <v-card-subtitle class="pb-0 text-center">
                  Member Type: {{ user.MemberType }}
                </v-card-subtitle>
                <v-card-subtitle class="py-0 text-center">
                  CID: {{ user.CID }}
                </v-card-subtitle>
                <v-card-subtitle class="py-0 text-center">
                  Shortcode: {{ user.Login }}
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-inline-flex justify-center">
                <v-card class="d-inline-flex pa-3">
                  <Qrcode :value="user.Login" size="150"></Qrcode>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-auto"
          type="card"
        ></v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from "@/store";
import { db } from "@/plugins/firebase";
import Qrcode from "qrcode.vue";
import Avatar from "@/components/Profile/Avatar.vue";

export default {
  fiery: true,
  components: {
    Qrcode,
    Avatar,
  },
  data() {
    return {
      user: this.$fiery(db.collection("Members").doc(store.state.user.Login)),
      attendance: this.$fiery(
        db.collection("Attendance").doc(store.state.user.Login),
        {
          sub: {
            EventsAttended: {
              query: (events) => events,
              key: "id",
            },
          },
        }
      ),
    };
  },
  computed: {
    score() {
      return this.attendance.EventsAttended.reduce(
        (a, b) => a + (b["points"] || 0),
        0
      );
    },
  },
};
</script>
